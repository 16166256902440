import { Elm } from '../elm/Main.elm';

export function initField(element, btnColor) {
    return Elm.Main.init({
        node: element,
        flags: {
            apiUrl: process.env.MAILER_URL,
            type: "demo",
            ...btnColor
        }
    });
}

export function initQAs(element, qas) {
    return Elm.Main.init({
        node: element,
        flags: {
            type: "faqs",
            ...qas
        }
    });
}


export function initContactForm(element) {
    const app = Elm.Main.init({
        node: element,
        flags: {
            apiUrl: process.env.MAILER_URL,
            subject: "1 new Opsly contact us submission",
            type: "contact",
        }
    });

    return function () {
        grecaptcha.render('g-recaptcha', {
            'sitekey': process.env.CAPTCHA_SITE_KEY,
            'callback': function onSubmit(val) {
                app.ports.captchaSubmit.send(val);
            },
        });
    };
}


export function initRequestADemoForm(element) {
    const app = Elm.Main.init({
        node: element,
        flags: {
            apiUrl: process.env.MAILER_URL,
            subject: "1 new Request a Demo submission",
            type: "contact",
        }
    });

    return function () {
        grecaptcha.render('g-recaptcha', {
            'sitekey': process.env.CAPTCHA_SITE_KEY,
            'callback': function onSubmit(val) {
                app.ports.captchaSubmit.send(val);
            },
        });
    };
}


export function initStartBtn(trigger, target) {
    trigger.addEventListener('click', function () {
        animate(document.scrollingElement || document.documentElement, "scrollTop", "", 0, target.offsetTop, 400, true);
    });
}

export function initAccountMenu(element) {
    element.querySelector('#signin').setAttribute('href', `${process.env.APP_URL}/signin`);
    element.querySelector('#signup').setAttribute('href', `${process.env.APP_URL}/signup`);
}

export function initRegisterCTA() {
    document.getElementById('signup-cta').setAttribute('href', `${process.env.APP_URL}/signup`);
}

export function initCarousel(carousel, btns) {
    if ((carousel || {}).nodeType !== Node.ELEMENT_NODE || (btns || {}).nodeType !== Node.ELEMENT_NODE) {
        return;
    }

    const slides = childNodes(carousel);
    // const indicators = childNodes(btns).map(el => childNodes(el)[0]);

    // if (slides.length !== indicators.length) {
    //     return;
    // }

    const offset = 100 / slides.length;
    // const colors = indicators.map(el => el.getAttribute('data-color'));

    let index = 0

    slides.map(el => el.style.opacity = '0');

    const setSlide = (ix) => {
        slides[ix].style.opacity = "1";
        // indicators.map(el => el.style.backgroundColor = '');
        // indicators[ix].style.backgroundColor = colors[ix];
        carousel.style.transform = `translateX(-${offset * ix}%)`;

        if (index == slides.length - 1) {
            nextBtn.style.display = "none";
            prevBtn.style.display = "";
        } else if (index == 0) {
            nextBtn.style.display = "";
            prevBtn.style.display = "none";
        } else {
            nextBtn.style.display = "";
            prevBtn.style.display = "";
        }
    };

    // const interval = setInterval(() => {
    //     slides[index].style.opacity = "0";

    //     if (index == slides.length - 1) {
    //         index = 0;
    //     } else {
    //         index += 1;
    //     }

    //     setTimeout(() => {
    //         setSlide(index);
    //     }, 200);

    // }, 5000);

    // childNodes(btns).map((el, ix) => el.addEventListener('click', () => {
    //     clearInterval(interval);
    //     setSlide(ix);
    // }, false));

    const prevBtn = childNodes(btns)[0];
    const nextBtn = childNodes(btns)[1];

    prevBtn.addEventListener('click', () => {
        if (index <= 0) {
            index = 0
        } else {
            index = index - 1;
        }
        setSlide(index);
    });
    nextBtn.addEventListener('click', () => {
        if (index >= slides.length - 1) {
            index = slides.length - 1;
        } else {
            index = index + 1;
        }
        setSlide(index);
    });


    return setSlide(0);
}

export function initSidebar(openBtn, closeBtn, sidebar) {
    if ((openBtn || {}).nodeType !== Node.ELEMENT_NODE || (closeBtn || {}).nodeType !== Node.ELEMENT_NODE || (sidebar || {}).nodeType !== Node.ELEMENT_NODE) {
        return;
    }

    openBtn.addEventListener('click', () => {
        sidebar.style.transform = 'translateX(-100%)';
    }, false);
    closeBtn.addEventListener('click', () => {
        sidebar.style.transform = '';
    }, false);
}

function childNodes(element) {
    return Array.prototype.slice
        .apply(element.childNodes)
        .filter(el => el.nodeType == Node.ELEMENT_NODE)
}

function animate(elem, style, unit, from, to, time, prop) {
    if (!elem) {
        return;
    }
    var start = new Date().getTime(),
        timer = setInterval(function () {
            var step = Math.min(1, (new Date().getTime() - start) / time);
            if (prop) {
                elem[style] = (from + step * (to - from)) + unit;
            } else {
                elem.style[style] = (from + step * (to - from)) + unit;
            }
            if (step === 1) {
                clearInterval(timer);
            }
        }, 25);
    if (prop) {
        elem[style] = from + unit;
    } else {
        elem.style[style] = from + unit;
    }
}