module Main exposing (main)

import Browser
import Contact
import Demo
import Faqs
import Html exposing (Html)
import Html.Styled as Styled
import Json.Decode as D exposing (Value)


type Model
    = NoElement
    | Demo Demo.Model
    | Faqs Faqs.Model
    | Contact Contact.Model


main : Program Value Model Msg
main =
    Browser.element
        { init = init
        , view = view
        , update = update
        , subscriptions =
            \model ->
                case model of
                    Contact data ->
                        Contact.subscriptions data
                            |> Sub.map ContactMsg

                    _ ->
                        Sub.none
        }


init : Value -> ( Model, Cmd Msg )
init json =
    D.decodeValue (initDecoder json) json
        |> Result.withDefault ( NoElement, Cmd.none )


initDecoder : Value -> D.Decoder ( Model, Cmd Msg )
initDecoder json =
    D.field "type" D.string
        |> D.andThen
            (\element ->
                case element of
                    "demo" ->
                        Demo.init json
                            |> Tuple.mapBoth Demo (Cmd.map DemoMsg)
                            |> D.succeed

                    "faqs" ->
                        Faqs.init json
                            |> Tuple.mapBoth Faqs (Cmd.map FaqsMsg)
                            |> D.succeed

                    "contact" ->
                        Contact.init json
                            |> Tuple.mapBoth Contact (Cmd.map ContactMsg)
                            |> D.succeed

                    _ ->
                        D.fail "unknown element"
            )



-- UPDATE


type Msg
    = DemoMsg Demo.Msg
    | FaqsMsg Faqs.Msg
    | ContactMsg Contact.Msg


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( msg, model ) of
        ( DemoMsg submsg, Demo data ) ->
            Demo.update submsg data
                |> Tuple.mapBoth Demo (Cmd.map DemoMsg)

        ( FaqsMsg submsg, Faqs data ) ->
            Faqs.update submsg data
                |> Tuple.mapBoth Faqs (Cmd.map FaqsMsg)

        ( ContactMsg submsg, Contact data ) ->
            Contact.update submsg data
                |> Tuple.mapBoth Contact (Cmd.map ContactMsg)

        _ ->
            ( model, Cmd.none )



-- VIEW


view : Model -> Html Msg
view model =
    case model of
        NoElement ->
            Html.text "Check your flags, there is no such element"

        Demo data ->
            Demo.view data
                |> Styled.toUnstyled
                |> Html.map DemoMsg

        Faqs data ->
            Faqs.view data
                |> Styled.toUnstyled
                |> Html.map FaqsMsg

        Contact data ->
            Contact.view data
                |> Styled.toUnstyled
                |> Html.map ContactMsg
